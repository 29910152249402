(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name neo
   * @description
   *
   */
  angular
    .module('neo', [
      'ngAria',
      'ngMaterial',
      'ngMessages',
      'ui.router',
      'ngResource',
      'md.data.table',
      'angular-jwt',
      'pascalprecht.translate',
      'uiGmapgoogle-maps',
      'googlechart',
      'neo.config',
      'neo.home',
      'neo.services',
      'neo.components',
      'neo.authentication',
      'neo.security',
      'neo.public',
      'neo.serverNotFound','neo.home.refereeNewInvoice','neo.home.resultats','neo.home.informes','neo.home.informestecnic'
      ,'neo.home.informestg','neo.home.informespublic'
    ]);
}());
